import { useStaticQuery, graphql } from "gatsby";
export const getGalleryPage = () => {
  const { strapiGalleryPage } = useStaticQuery(graphql`
    query GalleryPageQuery {
      strapiGalleryPage {
        gallery {
          desktop: image {
            publicURL
            childImageSharp {
              fixed(width: 1200, quality: 80) {
                src
              }
              fluid(maxWidth: 500, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          mobile: image {
            childImageSharp {
              fixed(width: 1200, quality: 80) {
                src
              }
              fluid(maxWidth: 180, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  return strapiGalleryPage;
};

import GatsbyImage from "gatsby-image";
import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { useGalleryPageGallery } from "./hooks/useGalleryPageGallery";

export interface GalleryPageGalleryProps {
  className?: string;
}

export const GalleryPageGallery = (props: GalleryPageGalleryProps) => {
  const { className = "" } = props;
  const gallery = useGalleryPageGallery();
  return (
    <section className={`className`}>
      <SimpleReactLightbox>
        <SRLWrapper key="gallery-page">
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 xl:gap-12">
            {gallery.map((item, index) => (
              <a
                href={item.publicURL}
                key={index}
                className="rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-all duration-300 ease-in-out"
              >
                <GatsbyImage
                  className="h-32 sm:h-48 xl:h-60  2xl:h-80 object-contain"
                  fluid={item.sources[0]}
                />
              </a>
            ))}
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>
    </section>
  );
};

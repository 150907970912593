import React, { ReactNode } from "react";

export interface SectionsWrapperProps {
  className?: string;
  children: ReactNode | ReactNode[];
}

export const SectionsWrapper = (props: SectionsWrapperProps) => {
  const { children } = props;
  return <div className={`space-y-16 md:space-y-28 xl:space-y-48`}>{children}</div>;
};

import { styled } from "linaria/react";
import { theme } from "../Theme/Theme";

export const PageHeading = styled.h1<{ mb?: string }>`
  font-size: 40px;
  color: ${theme.color.primary};
  font-weight: 700;
  margin-bottom: ${(props) => (props.mb ? props.mb : "20px")};

  @media (min-width: 768px) {
    font-size: 48px;
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 60px;
  }
`;

import { styled } from "linaria/react";
import React from "react";
import GalleryWaveSvg from "./assets/GallerySvg.svg";

export interface GalleryWaveProps {
  className?: string;
}

const Wave = styled.img`
  width: 100%;
  position: absolute;
  top: 80px;
  z-index: -1;
  display: none;
  object-fit: contain;
  filter: opacity(1);

  @media (min-width: 1280px) {
    display: flex;
  }
`;

export const GalleryWave = (props: GalleryWaveProps) => {
  return <Wave src={GalleryWaveSvg} alt="" />;
};

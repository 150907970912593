import { GalleryItem } from "../../../../../lib/model/Gallery";
import { useGalleryPage } from "../../../context/GalleryPageProvider";

export const useGalleryPageGallery = () => {
  const { gallery } = useGalleryPage();

  return gallery.map((image: GalleryItem) => ({
    publicURL: image.desktop.childImageSharp.fixed.src,
    childImageSharp: image.desktop.childImageSharp,
    sources: [
      image.desktop.childImageSharp.fluid,
      {
        ...image.mobile.childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
    ],
  }));
};

import React from "react";
import { GalleryPageGallery } from "../components/Gallery/components/GalleryPageGallery/GalleryPageGallery";
import { GalleryWave } from "../components/Gallery/components/GalleryWave/GalleryWave";
import { GalleryPageProvider } from "../components/Gallery/context/GalleryPageProvider";
import { Container } from "../components/Shared/Container/Container";
import { PageHeading } from "../components/Shared/Headings/PageHeading";
import Layout from "../components/Shared/Layout/Layout";
import { SectionsWrapper } from "../components/Shared/SectionsWrapper/SectionsWrapper";
import { SEO } from "../components/Shared/SEO/SEO";

const GalleryPage = () => (
  <Layout>
    <SEO title={"Galéria"} />
    <GalleryPageProvider>
      <Container>
        <PageHeading>Galéria</PageHeading>
        <SectionsWrapper>
          <GalleryPageGallery />
        </SectionsWrapper>
      </Container>
      <GalleryWave />
    </GalleryPageProvider>
  </Layout>
);

export default GalleryPage;
